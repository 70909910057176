import WertWidget from '@wert-io/widget-initializer';
import React from 'react';

import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import styles from '../Header/wert.module.scss';
import SimpleModal from '../Ui/Modals/SimpleModal';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

function WertWidgetPopup(props) {
  const address = useSelector((state) => state.wallet.address);

  console.log('address = ');
  console.log(address);

  if (address != null && address.startsWith('tz') && address.length === 36) {
    // Parameters for wertUI initialization
    const wertParams = {
      partner_id: '01FG3WGQT0E33RJJQ1E88MKCR4',
      address: address,
      origin: 'https://widget.wert.io',
      currency: 'USD',
      commodity: 'XTZ',
      commodities: 'XTZ',
      color_background: '#201c41',
      color_buttons: '#ceaefe',
      color_buttons_text: '#000',
      buttons_border_radius: '8',
      color_main_text: '#FFFFFF',
      // color_secondary_text: "#958E99",
      //color_secondary_buttons_hover: "#fff",
      color_secondary_buttons_text: '#CFCED1',
      color_secondary_buttons: '#0f051d',
      color_icons: '#958E99',
      color_links: '#9D5CFF',
      color_scroll_thumb: '#343052',
      color_scroll_track: '#201c41',
      color_buttons_shadow: '#000000',
      color_main_text_inactive: '#1F0E38',
      color_buttons_inactive: '#230c44',
    };
    const wertWidget = new WertWidget(wertParams);
    return (
      <SimpleModal
        open={props.open}
        title={''}
        onClose={() => props.hide(false)}
        isFullSizeOnMobile
        Name={'videop'}
        className="w-[100%] px-0 pb-0 md:w-[550px] max-w-[554px] buyCrypto"
      >
        <div className={clsx(styles.wertPopup)}>
          <iframe
            src={wertWidget.getEmbedUrl()}
            className="inner"
            allow="camera;"
            title="Wert"
            style={{
              width: isMobile ? '100%' : '500px',
              height: isMobile ? '540px' : '520px',
              border: '0px',
            }}
          ></iframe>
        </div>
      </SimpleModal>
    );
  } else {
    return (
      <div className="App">
        <h1>You seem have enterd a wrong wallet address</h1>
      </div>
    );
  }
}
WertWidgetPopup.propTypes = {
  hide: PropTypes.any,
  open: PropTypes.bool,
};

export default WertWidgetPopup;
