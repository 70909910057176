import { useEffect, useState } from 'react';
import { THEME } from '../constants/localStorage';

const useThemes = () => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const localTheme = 'dark';
    // ? Setting default theme as light
    toggleTheme({ defaultTheme: localTheme ?? 'dark' });
  }, []);

  const toggleTheme = ({ defaultTheme = null }) => {
    const newTheme = defaultTheme != null ? defaultTheme : theme === 'light' ? 'dark' : 'dark';

    setTheme(newTheme);

    document.documentElement.className = `${newTheme} theme-transiton`;
    localStorage.setItem(THEME, newTheme);

    setTimeout(() => {
      document.documentElement.className = `${newTheme}`;
    }, 1000);
  };

  return { theme };
};

export default useThemes;
